@if (isLoggedIn$ | async) {

<button class="btn feedback-button" type="submit" (click)="openFeedbackOffCanvas(content)">Feedback</button>
<header class="navbar sticky-top top-header">
  <div class="container-fluid justify-content-end g-0">
    <ul ngbNav class="d-flex">
      <li ngNavItem class="top-nav">
        <a id="site-search-button" class="search-button" (click)="onMegaMenuClick(10)">
          Search <i-bs [name]="iconNames.Search"></i-bs>
        </a>
        @if (megaMenuActiveIndex == searchMegaMenuIndex) {
        <div class="dropdown-menu dropdown-search megamenu container-fluid expanded bg-body-secondary">
          <div class="row g-0 search-row">
            <div class="col-auto">
              <i-bs [name]="iconNames.Search" width="24px" height="24px"></i-bs>
            </div>
            <div class="col-auto flex-fill position-relative">
              @if ((user$ | async)?.ipuser === false && searchString.trim().length > 0) {
              <button id="save-search-button" class="btn btn-outline-bankruptcydata search-save-filters" (click)="saveSiteSearch()">Save Search</button>
              }
              <input id="site-search-field" type="text" class="search-field" placeholder="Search..." [cdkTrapFocusAutoCapture]="megaMenuActiveIndex == searchMegaMenuIndex" [cdkTrapFocus]="megaMenuActiveIndex == searchMegaMenuIndex"
                [(ngModel)]="searchString" (ngModelChange)="doSearch()">
              @if (recentSearches.length) {
              <ul class="list-inline recent-search">
                <li class="list-inline-item">Recent searches: </li>
                @for (text of recentSearches; track text) {
                <li class="list-inline-item" (click)="loadRecentSearch(text)">
                  <span class="button">{{text}}</span>
                </li>
                }
                @for (search of (user$ | async)!.searches['site-search']; track search) {
                <li class="list-inline-item">
                  @if (search['type'] === 'search') {

                  <span class="button">
                    <a (click)="loadSiteSearch(search)">
                      {{ search['name'].replace('Site Search:', '') }}
                    </a>
                    @if (search.isOwner) {
                    <i-bs [name]="iconNames.Trash" (click)="deleteSavedSearch(search)"></i-bs>
                    }
                    @if (!search.isOwner) {
                    <i-bs [name]="iconNames.Asterisk" ngbPopover="Shared with you by someone else in your organization." triggers="mouseenter:mouseleave"></i-bs>
                    }
                    <i-bs [name]="iconNames.PencilSquare" (click)="editSavedSearch(search)"></i-bs>
                  </span>

                  }
                </li>
                }

              </ul>
              }
            </div>
            <div class="col-auto">
              <button id="site-search-close" type="button" class="btn-close" (click)="closeMegaMenus()"></button>
            </div>
          </div>
          @if (searchString.length >= searchStringMinLength) {
          <div class="row">
            <div class="col">
              @if (newsSearchResults$ | async; as articles) {

              <h3 class="column-header">
                News
                @if (articles.length > 0) {
                <button [libMasterRouterLink]="['news']" [queryParams]="getQueryParams('news')" class="btn btn-outline-bankruptcydata show-all">Show All</button>
                }
              </h3>
              @if (articles.length === 0) {
              <p>
                No results
              </p>
              }
              @for (article of articles; track trackbyArticleID(i, article); let i = $index) {

              @if (article.id > 0) {
              <div [libMasterRouterLink]="['news' , article.id]" [queryParams]="getQueryParams('news')">
                <div class="fw-bolder me-1">{{ article.publicationDate | date}}</div>
                <a class="fw-bold" [innerHTML]="article.title"></a>
                <p class="fs-6 search-snippets" [innerHTML]="article.snippets"></p>
              </div>
              }

              }

              } @else {

              <ng-container *ngTemplateOutlet="searchLoading; context: {title: 'News'}"></ng-container>

              }
            </div>
            <div class="col">
              @if (situationSearchResults$ | async; as articles) {

              <h3 class="column-header">
                Situations
                @if (numberOfResultsToShow(articles) > 0) {
                <button [libMasterRouterLink]="['situations']" [queryParams]="getQueryParams('situations')" class="btn btn-outline-bankruptcydata show-all">Show All</button>
                }
              </h3>
              @if (numberOfResultsToShow(articles) === 0) {
              <p>
                No results
              </p>
              }
              @for (article of articles; track trackbyArticleID(i, article); let i = $index) {

              @if (article.id > 0) {
              <div [libMasterRouterLink]="['situations' , article.id]" [queryParams]="getQueryParams('situations')">
                <a [innerHTML]="article.title"></a>
              </div>
              }

              }

              } @else {

              <ng-container *ngTemplateOutlet="searchLoading; context: {title: 'Situations'}"></ng-container>

              }
            </div>
            <div class="col">
              @if (organisationSearchResults$ | async; as articles) {

              <h3 class="column-header">
                Organizations
                @if (articles.length > 1) {
                <button [libMasterRouterLink]="['organizations']" [queryParams]="getQueryParams('organizations')" class="btn btn-outline-bankruptcydata show-all">Show All </button>
                }
              </h3>
              @if (articles.length < 2) {
              <p>
                No results
              </p>
              } @else {
                @for (article of articles; track trackbyArticleID(i, article); let i = $index) {

                @if (article.id > 0) {
                <div [libMasterRouterLink]="['organizations' , article.id]" [queryParams]="getQueryParams('organizations')">
                  <a [innerHTML]="article.title"></a>
                </div>
                }

                }
              }

              } @else {

              <ng-container *ngTemplateOutlet="searchLoading; context: {title: 'Organizations'}"></ng-container>

              }
              @if (personSearchResults$ | async; as articles) {

              <h3 class="column-header">
                People
                @if (articles.length > 0) {
                <button [libMasterRouterLink]="['people']" [queryParams]="getQueryParams('people')" class="btn btn-outline-bankruptcydata show-all">Show All</button>
                }
              </h3>
              @if (articles.length < 2) {
              <p>
                No results
              </p>
              }
              @for (article of articles; track trackbyArticleID(i, article); let i = $index) {

              @if (article.id > 0) {
              <div [libMasterRouterLink]="['people' , article.id]" [queryParams]="getQueryParams('people')">
                <a [innerHTML]="article.title"></a>
              </div>
              }

              }

              } @else {

              <ng-container *ngTemplateOutlet="searchLoading; context: {title: 'People'}"></ng-container>

              }
            </div>
            <div class="col">
              @if (documentSearchResults$ | async; as articles) {

              <h3 class="column-header">
                Documents
                @if (articles.length > 0) {
                <button [libMasterRouterLink]="['documents']" [queryParams]="getQueryParams('documents')" class="btn btn-outline-bankruptcydata show-all">Show All</button>
                }
              </h3>
              @if (articles.length === 0) {
              <p>
                No results
              </p>
              }
              @for (article of articles; track trackbyArticleID(i, article); let i = $index) {

              @if (article.id >= 0) {
              <div [libMasterRouterLink]="['situations' , article.casedocumentinfo.articleID]" [queryParams]="getQueryParams('documents')" [fragment]="'99'">
                <a>
                  <strong [innerHTML]="article.case_name"></strong><br>
                </a>
              </div>
              }
              <span class="document-description-abbreviated" [innerHTML]="article.description"></span>
              <div class="search-snippets" [innerHTML]="article.snippets"></div>

              }

              } @else {

              <ng-container *ngTemplateOutlet="searchLoading; context: {title: 'Documents'}"></ng-container>

              }
            </div>
          </div>
          }
        </div>
        }
      </li>
      <li ngNavItem style="padding-right: 15px;" class="top-nav">
        <three-position-switch [value]="this.themeService.storedTheme" (newvalue)="setTheme($event, true)" ngbPopover="Light, auto or dark. Auto mode depends on what your computer is using." triggers="mouseenter:mouseleave"
          popoverTitle="Color mode"></three-position-switch>
      </li>
      <li ngbNavItem class="top-nav">
        <a class="search-button bd-mid-green-colour" type="submit" [routerLink]="'/my-account'" (click)="navbarCollapsed = true"> My Account <i-bs [name]="iconNames.PersonFill" width="18px" height="18px" class="bd-green-colour"></i-bs></a>
      </li>
    </ul>
  </div>
</header>
<header class="navbar sticky-top navbar-expand-lg bg-body-secondary pb-0 main-header">
  <div class="container-fluid bg-body-secondary">
    <a class="navbar-brand" routerLink="/home" [inlineSVG]="'/assets/logo.svg'" (click)="navbarCollapsed = true"></a>
    <button class="navbar-toggler" type="button" type="button" (click)="navbarCollapsed = !navbarCollapsed">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div [ngbCollapse]="navbarCollapsed" class="navbar-collapse collapse m-0 justify-content-between">
      <ul class="menu-padding"></ul>
      <ul ngbNav [(activeId)]="activeId" class="navbar-nav nav-bordered nav-underline">
        @for (menu of (menus$ | async); track menu.id; let i = $index) {

        <li ngbNavItem class="dropdown has-megamenu" [attr.id]="'nav-'+ menu.id">
          @if ((menu.columns ?? []).length === 0) {

          <button ngbNavLink class="nav-link" [routerLinkActive]="['active']" [routerLink]="[menu.link]" (click)="navbarCollapsed = true">
            {{ menu.title }}
            @if (menu.badge) {
              <span class="badge rounded-pill bd-bg-green-colour">{{ menu.badge }}</span>
            }
          </button>

          } @else {

          <button ngbNavLink class="nav-link" (click)="onMegaMenuClick(i)" [class.active]="menu.area === activeBaseURL" (mouseenter)="onMegaMenuEnter(i)" (mouseleave)="onMegaMenuLeave(i)">
            @if (isMegaMenu(menu)) {
            <i-bs class="d-inline-block" [@openClose]="megaMenuActiveIndex == i ? 'open' : 'closed'" [name]="iconNames.ChevronDown"></i-bs>
            }
            {{ menu.title }}
            @if (menu.badge) {
              <span class="badge rounded-pill bd-bg-green-colour">{{ menu.badge }}</span>
            }
            &nbsp;
          </button>
          @if (isMegaMenu(menu) && megaMenuActiveIndex == i) {
          <div class="dropdown-menu megamenu container-fluid expanded bg-body-secondary" [@megaMenuTrigger]="megaMenuActiveIndex == i ? 'enter' : 'leave'">
            <div class="container">
              <div class="row">
                @for (column of menu.columns | keyvalue; track column) {

                @if (column.value.title) {

                <div class="col-lg-{{12 / numberOfMegaMenuColumns(menu) }} col-6">
                  <div class="col-megamenu">
                    <h5 class="border-bottom border-bd-green-colour p-2 ps-0 menu-item">{{column.value.title}}</h5>
                    <ng-container *ngTemplateOutlet="menuTemplate; context: {$implicit: column.value.children, area: menu.area, template: column.value.template ?? 'default'}"></ng-container>
                  </div>
                </div>

                } @else {

                <div class="col-lg-auto col-6">
                  <div class="col-megamenu">
                    <h5 class="p-2">&nbsp;</h5>
                    <ng-container *ngTemplateOutlet="menuTemplate; context: {$implicit: column.value.children, area: menu.area, template: column.value.template ?? 'default'}"></ng-container>
                  </div>
                </div>
                }
                }
              </div>
            </div>
          </div>
          }

          }
        </li>

        }
      </ul>
      <ul class="menu-padding-right"></ul>
    </div>
  </div>
</header>
<app-breadcrumbs></app-breadcrumbs>
}

@if (megaMenuActiveIndex >= 0) {
<div id="overlay" (click)="closeMegaMenus()" (mouseenter)="closeMegaMenus(true)"></div>
}

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
<saved-searches></saved-searches>

<router-outlet (activate)="clearToasts()"></router-outlet>

<ng-template #menuTemplate let-children let-area="area" let-template="template">
  <ng-container>
    @switch (template) {
    @case ('default') {
    <ul class="list-unstyled">
      @for (child of children; track child) {
      <li class="menu-item">
        @if (child.EventDate) {

        <span class="text-body-secondary">{{ child.EventDate | date:'medium' }}</span><br>

        }
        <a (click)="navbarCollapsed = true" [routerLink]="[child.link]" [queryParams]="child.params" [state]="child.state">
          @if (child.icon) {
          <i-bs [name]="child.icon" width="0.88rem" height="0.88rem"></i-bs>
          }
          @if (child.otherOptions?.badge) {
          <span class="badge rounded-pill d-inline-block me-1 mb-1" [ngClass]="child.otherOptions?.badgeColour">
            {{child.otherOptions?.badge}}
          </span>
          }
          {{ child.title }}
        </a>
      </li>
      }
    </ul>
    }
    @case ('featured') {
    <div class="row g-2 row-cols-2">
      @for (child of children; track child; let i = $index) {

      <div class="col">
        <a class="card h-100 p-2 bg-body menu-item" [routerLink]="[child.link]" [queryParams]="addRouteBuster(child.params)" [state]="child.state" (click)="navbarCollapsed = true" [replaceUrl]="true">
          @if (child.icon) {
          <i-bs [name]="child.icon" width="0.88rem" height="0.88rem"></i-bs>
          }
          @if (child.otherOptions?.badge) {
          <div class="badge rounded-pill d-inline-block me-1 mb-1" [ngClass]="child.otherOptions?.badgeColour">
            {{child.otherOptions?.badge}}
          </div>
          }
          {{ child.title }}
          <div class="corner"></div>
        </a>
      </div>

      }
    </div>
    }
    @case ('prominent') {
    <ul class="list-unstyled">
      @for (child of children; track child; let i = $index) {
        @if (hideChildByFeatureFlag(area, child)) {

        <li class="menu-item mb-2">
          <button (click)="navbarCollapsed = true" class="btn btn-gradient btn-lg btn-block quick-link" [routerLink]="[child.link]" [queryParams]="addRouteBuster(child.params)" [state]="child.state">
            <i-bs [name]="iconNames.BoxArrowUpRight" width="0.88rem" height="0.88rem"></i-bs>
            {{ child.title }}
            @if (child.otherOptions?.badge) {
              <span class="badge rounded-pill d-inline-block me-1 mb-1" [ngClass]="child.otherOptions?.badgeColour">
                {{child.otherOptions?.badge}}
              </span>
            }
          </button>
        </li>
      }
      }
    </ul>
    }
    @case ('search') {
    <ul class="list-unstyled">
      <li class="menu-item">Your Searches</li>
      @for (search of (user$ | async)!.searches[area]; track search) {
      <li class="menu-item">
        @if (search['type'] === 'search') {

        <a (click)="gotoSearch(search)">
          {{ search['name'] }}
        </a>
        @if (search.isOwner) {
        <i-bs [name]="iconNames.Trash2" (click)="deleteSavedSearch(search)"></i-bs>
        }
        @if (!search.isOwner) {
        <i-bs [name]="iconNames.Asterisk" ngbPopover="Shared with you by someone else in your organization." triggers="mouseenter:mouseleave"></i-bs>
        }
        <i-bs [name]="iconNames.PencilSquare" (click)="editSavedSearch(search)"></i-bs>

        }
      </li>
      }
      <li class="menu-item">Popular Searches</li>
      @for (search of children; track search) {
      <li class="menu-item">
        @if (search['type'] === 'search') {

        <a (click)="gotoSearch(search)">
          {{ search['name'] }}
        </a>

        }
      </li>
      }
    </ul>
    }
    }
  </ng-container>
  <ng-template #searchMenuTemplate>
  </ng-template>
</ng-template>


<ng-template #searchLoading let-title="title">
  <h3 class="column-header">
    {{title}}
  </h3>
  <div class="d-flex justify-content-center">
    <div class="spinner-border m-5" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>

<ng-template #content let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title" title="Feedback">Feedback</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
  </div>
  <div class="offcanvas-body">
    @if (feedbackFormState == 'ready') {

    <div class="row">
      <div class="col">
        <p>If you need help, find a problem or want to provide us with information on a situation, such as a bankruptcy case, that you have worked on then fill in the form below and someone will get back to you as soon as possible.</p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p><strong>Your information</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td>Name</td>
              <td>{{ user.ContactFirst }} {{ user.ContactLast }}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{{ user.Email }}</td>
            </tr>
            <tr>
              <td>Company</td>
              <td>{{ user.Company }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <form name="feedback" #ngFeedbackForm="ngForm" (ngSubmit)="ngFeedbackForm.valid &&  sendFeedback()" novalidate>
      <div class="row">
        <div class="col">
          <select ngbAutofocus class="form-select" required name="reason" [(ngModel)]="feedbackForm.reason" #reason="ngModel" [class.is-invalid]="reason.errors?.['required']">
            <option selected disabled value="">Choose an action</option>
            <option value="Arrange a demo of the site">Arrange a demo of the site</option>
            <option value="I need help">I need help</option>
            <option value="Incorrect information">Incorrect information</option>
            <option value="Provide extra information">Provide extra information</option>
            <option value="Other">Other</option>
          </select>
          <div class="invalid-feedback" [class.d-block]="reason.errors?.['required']">
            Please choose an action.
          </div>
        </div>
      </div>
      <div class="row my-2">
        <div class="col">
          <div class="form-floating">
            <textarea class="form-control" placeholder="Leave a comment here" id="messageArea" style="height: 200px;" name="message" [(ngModel)]="feedbackForm.message" required minlength="10" #message="ngModel"></textarea>
            <label for="messageArea">Comments</label>
          </div>
          <div class="invalid-feedback" [class.d-block]="message.errors?.['minlength'] || message.errors?.['required']">
            Please include a message.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button type="submit" class="btn btn-outline-bankruptcydata" [disabled]="!ngFeedbackForm.form.valid">Send</button>
        </div>
      </div>
    </form>

    }
    @if (feedbackFormState == 'busy') {

    <div class="row">
      <div class="col">
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>

    }
    @if (feedbackFormState == 'sent') {

    <div class="row">
      <div class="col">
        <p>Your message has been sent.</p>
      </div>
    </div>

    }
    @if (feedbackFormState == 'error') {

    <div class="row">
      <div class="col">
        <p>An error has occurred – please try again.</p>
      </div>
    </div>

    }
    <div class="build-date">
      {{ commitTimeStamp }}
    </div>
  </div>
</ng-template>