import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { BreadcrumbButtonBarService } from 'src/app/services/breadcrumb-button-bar-service'
import { ButtonConfiguration } from 'src/app/services/button-configuration.interface'
import { NgxBootstrapIconsModule } from 'ngx-bootstrap-icons'

@Component({
    selector: 'breadcrumb-buttons',
    templateUrl: './breadcrumb-buttons.component.html',
    styleUrls: ['./breadcrumb-buttons.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgxBootstrapIconsModule]
})
export class BreadcrumbButtonsComponent implements OnInit {
  buttons: ButtonConfiguration[] = []

  constructor(private buttonBarService: BreadcrumbButtonBarService, private ref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.buttonBarService.getButtons().subscribe((buttons) => {
      setTimeout( () => {
        this.buttons = buttons
        this.ref.detectChanges()
      }, 100)
    })
  }
}