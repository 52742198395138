import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { take } from 'rxjs'
import { selectUser } from 'src/app/state/user.reducer'

@Component({
  selector: 'app-goto-saved-search',
  standalone: true,
  imports: [],
  templateUrl: './goto-saved-search.component.html',
  styleUrls: ['./goto-saved-search.component.scss']
})
export class GotoSavedSearchComponent implements OnInit {

  public constructor(
    private store: Store<any>,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.store.select(selectUser).pipe(take(1)).subscribe(user => {
      const alertID = this.route.snapshot.paramMap.get('alertid') ?? ''

      if (user !== undefined && alertID !== '') {
        for (const [area, areaSavedSearch] of Object.entries(user!.searches)) {
          for (const search of areaSavedSearch) {
            if (search.id === alertID) {
              this.router.navigateByUrl('/' + area, {
                state: search.parameters
              })
              return
            }
          }
        }
      }
    })
  }
}
