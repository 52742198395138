import { Component } from '@angular/core'
import { Store } from '@ngrx/store'
import { IconNamesEnum, NgxBootstrapIconsModule } from 'ngx-bootstrap-icons'
import { Observable } from 'rxjs'
import { isLoggedIn } from 'src/app/state/user.reducer'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { RouterLink } from '@angular/router'
import { NgbNav, NgbNavItem, NgbNavItemRole } from '@ng-bootstrap/ng-bootstrap'
import { AsyncPipe } from '@angular/common'

@Component({
    selector: 'app-public-page-header',
    templateUrl: './public-page-header.component.html',
    styleUrls: ['./public-page-header.component.scss'],
    standalone: true,
    imports: [NgbNav, NgbNavItem, NgbNavItemRole, RouterLink, NgxBootstrapIconsModule, InlineSVGModule, AsyncPipe]
})
export class PublicPageHeaderComponent {
  public iconNames = IconNamesEnum

  public isLoggedIn$: Observable<boolean>

  constructor(
    public store: Store<any>,
  ) {
    this.isLoggedIn$ = this.store.select(isLoggedIn)
  }

}
