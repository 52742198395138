<app-input-dialog #saveFilteringSearchDialog [title]="saveDialogTitle" (stepChanged)="saveSearchStage($event)">
    <input-dialog-step button="<i class='fa fa-floppy-o'></i> Save&hellip;" [checkValidity]="checkSavedSearchForm">
        <form #savedFilteringSearchForm>
            <div class="row py-2">
                <div class="col">
                    <label for="searchName">Name</label>
                    <input type="text" class="form-control" id="searchName" name="searchName" required [class.is-invalid]="!searchNameIsNotDuplicate" [(ngModel)]="savedSearchModel.name" (focus)="$any($event.target).select()" ngbAutofocus [attr.disabled]="savedSearchModel.type === 'follow' ? '' : null">
                    <div class="invalid-feedback">
                        Name in use
                    </div>
                </div>
            </div>
            @if (parameters['options']?.length) {
<div class="row py-2">
                <div class="col" style="columns: 2;">
                    <label for="searchName">Options</label>
                    @for (option of parameters['options']; track option) {
  <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" [id]="option[0]" [(ngModel)]="option[1]" [ngModelOptions]="{standalone: true}">
                        <label class="form-check-label" [for]="option[0]">{{ option[0] }}</label>
                    </div>
}
                </div>
            </div>
}
            @if (savedSearchModel.type === 'search') {
<div class="row">
                <div class="col-auto">
                    <div class="form-check form-switch">
                        <input class="form-check-input" name="sendAlerts" type="checkbox" role="switch" id="emailAlertsSwitch" [(ngModel)]="savedSearchModel.sendAlerts">
                        <label class="form-check-label" for="emailAlertsSwitch">Send email alerts</label>
                    </div>
                </div>
                @if (savedSearchModel.isOwner) {
<div class="col-auto" >
                    <div class="form-check form-switch">
                        <input class="form-check-input" name="shareSavedSearch" type="checkbox" role="switch" id="shareSearchSwitch" [(ngModel)]="savedSearchModel.shareSavedSearch">
                        <label class="form-check-label" for="shareSearchSwitch">
                            Share with organization <i-bs [name]="iconNames.InfoCircle" ngbPopover="Other people in your organization will be able to see this search and set their own alerts."
                            triggers="mouseenter:mouseleave"
                            popoverTitle="Shared searches"
                        ></i-bs></label>
                    </div>
                </div>
}
            </div>
}
            @if (savedSearchModel.sendAlerts) {

                <div class="row align-items-center">
                    <div class="col-auto">
                        <div class="form-check py-1">
                            <input class="form-check-input" type="radio" name="frequency" id="hourlyEmails" value="hourly" [(ngModel)]="savedSearchModel.frequency">
                            <label class="form-check-label" for="hourlyEmails">
                                Hourly
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto">
                        <div class="form-check py-1">
                            <input class="form-check-input" type="radio" name="frequency" id="dailyEmails" value="daily" [(ngModel)]="savedSearchModel.frequency">
                            <label class="form-check-label" for="dailyEmails">
                                Daily
                            </label>
                        </div>
                    </div>
                    @if (savedSearchModel.frequency == 'daily') {
<div class="col-auto" >
                        at
                        <select name="hour" class="form-select form-select-sm alert-frequency" [(ngModel)]="savedSearchModel.hour">
                            <option [ngValue]="0">12am</option>
                            <option [ngValue]="1">1am</option>
                            <option [ngValue]="2">2am</option>
                            <option [ngValue]="3">3am</option>
                            <option [ngValue]="4">4am</option>
                            <option [ngValue]="5">5am</option>
                            <option [ngValue]="6">6am</option>
                            <option [ngValue]="7">7am</option>
                            <option [ngValue]="8">8am</option>
                            <option [ngValue]="9">9am</option>
                            <option [ngValue]="10">10am</option>
                            <option [ngValue]="11">11am</option>
                            <option [ngValue]="12">12pm</option>
                            <option [ngValue]="13">1pm</option>
                            <option [ngValue]="14">2pm</option>
                            <option [ngValue]="15">3pm</option>
                            <option [ngValue]="16">4pm</option>
                            <option [ngValue]="17">5pm</option>
                            <option [ngValue]="18">6pm</option>
                            <option [ngValue]="19">7pm</option>
                            <option [ngValue]="20">8pm</option>
                            <option [ngValue]="21">9pm</option>
                            <option [ngValue]="22">10pm</option>
                            <option [ngValue]="23">11pm</option>
                        </select> {{ savedSearchModel.timeZoneShortName }}
                    </div>
}
                </div>
                <div class="row align-items-center">
                    <div class="col-auto">
                        <div class="form-check py-1">
                            <input class="form-check-input" type="radio" name="frequency" id="weeklyEmails" value="weekly" [(ngModel)]="savedSearchModel.frequency">
                            <label class="form-check-label" for="weeklyEmails">
                                Weekly
                            </label>
                        </div>
                    </div>
                    @if (savedSearchModel.frequency == 'weekly') {
<div class="col-auto" >
                        at
                        <select name="hour" class="form-select form-select-sm alert-frequency" [(ngModel)]="savedSearchModel.hour">
                            <option [ngValue]="0">12am</option>
                            <option [ngValue]="1">1am</option>
                            <option [ngValue]="2">2am</option>
                            <option [ngValue]="3">3am</option>
                            <option [ngValue]="4">4am</option>
                            <option [ngValue]="5">5am</option>
                            <option [ngValue]="6">6am</option>
                            <option [ngValue]="7">7am</option>
                            <option [ngValue]="8">8am</option>
                            <option [ngValue]="9">9am</option>
                            <option [ngValue]="10">10am</option>
                            <option [ngValue]="11">11am</option>
                            <option [ngValue]="12">12pm</option>
                            <option [ngValue]="13">1pm</option>
                            <option [ngValue]="14">2pm</option>
                            <option [ngValue]="15">3pm</option>
                            <option [ngValue]="16">4pm</option>
                            <option [ngValue]="17">5pm</option>
                            <option [ngValue]="18">6pm</option>
                            <option [ngValue]="19">7pm</option>
                            <option [ngValue]="20">8pm</option>
                            <option [ngValue]="21">9pm</option>
                            <option [ngValue]="22">10pm</option>
                            <option [ngValue]="23">11pm</option>
                        </select> {{ savedSearchModel.timeZoneShortName }}
                        on
                        <select name="day" class="form-select form-select-sm alert-frequency" [(ngModel)]="savedSearchModel.day">
                            <option [ngValue]="0">Monday</option>
                            <option [ngValue]="1">Tuesday</option>
                            <option [ngValue]="2">Wednesday</option>
                            <option [ngValue]="3">Thursday</option>
                            <option [ngValue]="4">Friday</option>
                            <option [ngValue]="5">Saturday</option>
                            <option [ngValue]="6">Sunday</option>
                        </select>
                    </div>
}
                </div>

}
        </form>
    </input-dialog-step>
    <input-dialog-step button="">
        <ngb-progressbar [striped]="true" [animated]="true" [value]="exportProgress"></ngb-progressbar>
    </input-dialog-step>
</app-input-dialog>

<app-input-dialog #deleteFilteringSavedSearchDialog title="Delete Saved Search" (stepChanged)="deleteSavedSearchStep($event)">
    <input-dialog-step button="<i class='fa fa-trash-o'></i> Ok &hellip;">
        <ngb-alert type="danger" [dismissible]="false">
            Delete saved search "{{savedSearchModel.name}}"
        </ngb-alert>
    </input-dialog-step>
</app-input-dialog>
