import { Component, TemplateRef } from '@angular/core'

import { ToastService } from 'src/app/services/toast.service'
import { NgTemplateOutlet } from '@angular/common'
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap'
import { SafeHtmlPipe } from '../../pipes/safehtml.pipe'

@Component({
	selector: 'app-toasts',
	standalone: true,
	imports: [NgbToastModule, NgTemplateOutlet, SafeHtmlPipe],
	templateUrl: './toasts-container.component.html',
	host: { class: 'toast-container position-fixed end-0 p-3', style: 'top: 100px; z-index: 1200' },
})
export class ToastsContainer {
	constructor(
		public toastService: ToastService
	) {}

	isTemplate(toast: { textOrTpl: any; }) {
		return toast.textOrTpl instanceof TemplateRef
	}
}