import { Title } from '@angular/platform-browser'
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { exhaustMap } from 'rxjs/operators'
import { Article } from './article.model'

@Injectable()
export class TitleEffects {
    loadTitle$ = createEffect(
        () => this.actions$.pipe(
            ofType('[Title] Set Title'),
            exhaustMap((action) => {
                if ((action as any).article) {
                    this.title.setTitle( ((action as any).article  as Article).title + ' | Bankruptcy Data')
                }
                return of({ type: "[Title] Title Set" })
            }
            )
        )
    )

    constructor(
        private actions$: Actions,
        private title: Title,
    ) { }

}
