import { Component, OnDestroy, ViewChild, ElementRef } from '@angular/core'
import { Dictionary } from '@ngrx/entity'
import { Subscription } from 'rxjs'
import { SavedSearchService } from 'src/app/services/saved-search.service'
import { InputDialogComponent, StepState, InputDialogStepComponent } from '../input-dialog/input-dialog.component'
import { BackEndService } from 'src/app/services/backend.service'
import { ToastService } from 'src/app/services/toast.service'
import { Store } from '@ngrx/store'
import { IconNamesEnum, NgxBootstrapIconsModule } from 'ngx-bootstrap-icons'
import { NgbPopover, NgbProgressbar, NgbAlert } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule } from '@angular/forms'
@Component({
    selector: 'saved-searches',
    templateUrl: './saved-searches.component.html',
    styleUrls: ['./saved-searches.component.scss'],
    standalone: true,
    imports: [InputDialogComponent, InputDialogStepComponent, FormsModule, NgxBootstrapIconsModule, NgbPopover, NgbProgressbar, NgbAlert]
})

export class SavedSearchesComponent implements OnDestroy {
    @ViewChild('saveFilteringSearchDialog') saveSearchDialogComponent: InputDialogComponent = {} as InputDialogComponent
    @ViewChild('savedFilteringSearchForm') savedSearchForm: ElementRef = {} as ElementRef
    @ViewChild('deleteFilteringSavedSearchDialog') deleteSavedSearchDialogComponent: InputDialogComponent = {} as InputDialogComponent

    saveSearchsubscription: Subscription
    deleteSearchsubscription: Subscription
    editSearchsubscription: Subscription

    public iconNames = IconNamesEnum

    public savedSearchModel = {
        id: '',
        name: '',
        type: '',
        sendAlerts: false,
        shareSavedSearch: false,
        frequency: 'daily',
        hour: 7,
        day: 0,
        timeZoneShortName: new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2],
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        isOwner: false
    }
    saveDialogTitle = ''
    editExisting = false

    area: string = ''
    parameters: Dictionary<any> = {}
    searchNameIsNotDuplicate = true

    exportProgress: number = 100

    constructor(
        public store: Store<any>,
        public savedSearchService: SavedSearchService,
        public backendService: BackEndService,
        public toastService: ToastService,
    ) {
        this.saveSearchsubscription = savedSearchService.searchToSave$.subscribe(
            search => {
                this.editExisting = false
                this.area = search.area
                this.parameters = search.parameters
                this.savedSearchModel = {
                    ...this.savedSearchModel,
                    name: search.name,
                    sendAlerts: false,
                    frequency: 'daily',
                    hour: 7,
                    day: 0,
                    id: '',
                    type: search.type,
                    shareSavedSearch: false,
                    isOwner: true
                }
                if (search.type === 'followdocs') {
                    this.saveDialogTitle = 'Follow Documents on ' + search.parameters.name
                    this.savedSearchModel.sendAlerts = true
                } else if (search.type === 'follow') {
                    this.saveDialogTitle = 'Follow...'
                    this.savedSearchModel.sendAlerts = true
                } else {
                    this.saveDialogTitle = 'Save This Search'
                }
                this.removeWasValidatedFromForm()
                this.saveSearchDialogComponent.showModal()
            }
        )
        this.deleteSearchsubscription = savedSearchService.searchToDelete$.subscribe(
            search => {
                this.savedSearchModel = search
                this.deleteSavedSearchDialogComponent.showModal()
            }
        )
        this.editSearchsubscription = savedSearchService.searchToEdit$.subscribe(
            search => {
                this.editExisting = true
                this.area = search.area
                this.parameters = search.parameters
                this.savedSearchModel = structuredClone(search)
                this.saveDialogTitle = 'Edit this search'
                this.removeWasValidatedFromForm()
                this.saveSearchDialogComponent.showModal()
            }
        )
        this.checkSavedSearchForm = this.checkSavedSearchForm.bind(this)
    }

    removeWasValidatedFromForm() {
        const form: HTMLFormElement = (this.savedSearchForm.nativeElement as HTMLFormElement)
        form.classList.remove('was-validated')
    }

    ngOnDestroy() {
        this.saveSearchsubscription.unsubscribe()
    }

    checkSavedSearchForm(): boolean {
        const form: HTMLFormElement = (this.savedSearchForm.nativeElement as HTMLFormElement)
        form.classList.add('was-validated')
        this.searchNameIsNotDuplicate = true // TODO !this.recentSearches[1].searches.some((search) => search.name == searchName)
        return form.checkValidity() && this.searchNameIsNotDuplicate
    }

    saveSearchStage(returned: StepState) {
        if (returned.step === 2) {
            if (this.editExisting) {
                this.backendService.editSavedSearch(this.savedSearchModel.id, {
                    area: this.area,
                    search: {
                        ...this.savedSearchModel,
                    }
                }).subscribe((response: any) => {
                    this.saveSearchDialogComponent.advanceToNextStep()
                    if (response.ok) {
                        this.toastService.show('Query successfully saved to favorites.')
                    } else {
                        this.toastService.show('Query NOT saved to favorites.')
                    }
                })
            } else {
                this.backendService.createSavedSearch({
                    area: this.area,
                    search: {
                        ...this.savedSearchModel,
                        parameters: this.parameters
                    }
                }).subscribe((response: any) => {
                    this.saveSearchDialogComponent.advanceToNextStep()
                    if (response.ok) {
                        this.toastService.show('Query successfully saved to favorites.')
                    } else {
                        this.toastService.show('Query NOT saved to favorites.')
                    }
                })
            }
        }
    }

    deleteSavedSearchStep(returned: StepState) {
        if (returned.step === 2) {
            this.backendService.deleteSavedSearch(this.savedSearchModel.id).subscribe((response: any) => {
                if (response.ok) {
                    this.store.dispatch({ type: '[User] Update User', user: response.user })
                    this.saveSearchDialogComponent.advanceToNextStep()
                    this.toastService.show('Query deleted.')
                } else {
                    this.toastService.show('Query not deleted.')
                }
            })
        }
    }
}