<app-public-page-header></app-public-page-header>

@if (loading) {
<div class="container">
  <div class="row">
    <div class="col">
      <div class="m-4 progress-container">
        <ngb-progressbar [value]="100" [striped]="true" [animated]="true"></ngb-progressbar>
      </div>
    </div>
  </div>
</div>
}

<ng-scrollbar class="ngr-scrollbar"  visibility="visible">
  @if (area == 'news') {
  <app-news-article [articleID]="articleID"></app-news-article>
  }
</ng-scrollbar>

<ng-template #registrationModal let-modal>
  <div class="modal-header">
    <div class="text-center">
      <img class="img-fluid" src="/assets/logo.svg">
    </div>
  </div>
  <div class="modal-body">
    <h4 class="modal-title">Registration Required</h4>
    <p>We require you to register with us to be able to access this content.</p>
    <form (ngSubmit)="onApplyForTrial()" ngNativeValidate class="needs-validation">

      <div class="form-floating mb-4">
        <input [(ngModel)]="userFullname" name="fullname" type="text" required class="form-control" id="fullname" placeholder="Full name...">
        <label class="form-label" for="fullname">Full name</label>
      </div>

      <div class="form-floating mb-4">
        <input [(ngModel)]="userEmail" name="email" type="email" required class="form-control" id="email" placeholder="Work email">
        <label class="form-label" for="email">Work email address</label>
      </div>
      @if (trialMessage.length) {
      <ngb-alert type="danger" [dismissible]="false">You must provide a <strong>work email address</strong></ngb-alert>
      }

      <div class="form-floating mb-4">
        <input [(ngModel)]="userTelephone" name="email" type="tel" required class="form-control" id="email" placeholder="Telephone number">
        <label class="form-label" for="email">Telephone number</label>
      </div>

      <div class="form-floating mb-4">
        <input [(ngModel)]="userOrganization" name="email" type="text" required class="form-control" id="email" placeholder="Organization name">
        <label class="form-label" for="email">Organization name</label>
      </div>

      <div class="form-check">
        <input [(ngModel)]="trialRequested" class="form-check-input" type="checkbox" id="trialRequest" [checked]="trialRequested" (change)="trialRequested = !trialRequested">
        <label class="form-check-label" for="trialRequest">
          Request a trial of the BankruptcyData.com site
        </label>
      </div>

      <div class="text-center pt-1 mb-5 pb-1">
        <div class="d-grid gap-2">
          @if (isLoading) {
            <button class="btn btn-bankruptcydata btn-block mb-3" type="button" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>
          } @else {
          <button class="btn btn-bankruptcydata btn-block mb-3" type="submit">
            Register
          </button>
          }
        </div>
      </div>
    </form>
  </div>
  \
</ng-template>